//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapGetters, mapActions } from 'vuex';
import i18n from '@/vueI18n'

export default {
  name: 'app-terms-and-conditions-page',

  data() {
    return {
      id: 'termsAndConditions',
      value: {
        en: '',
        ar: ''
      },
    };
  },
  computed: {
    ...mapGetters({
      record: 'home/record',
      findLoading: 'home/findLoading',
      saveLoading: 'home/saveLoading',
    }),
    currentLanguageCode() {
      return i18n.locale
    },
  },
  async created() {
    await this.doFind(this.id)
    this.value = this.record ? this.record.value : this.value
  },
  methods: {
    ...mapActions({
      doFind: 'home/doFind',
    }),
    i18n(key, args) {
      return this.$t(key, args);
    },
  },
};
